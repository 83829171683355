<template>
    <div class="not-find">
        <wj-image
            :src="require('@/assets/images/404.png')"
            class="notfind-img"
            alt="页面404"
        ></wj-image>
        <div class="f16">糟糕，页面被带走了</div>
        <wj-button type="primary" size="medium" class="back-btn" @click="backToHome"
            >返回首页</wj-button
        >
    </div>
</template>
<script>
import { Image, Button } from '@wenjuan/ui';
export default {
    data() {
        return {};
    },
    components: {
        'wj-image': Image,
        'wj-button': Button,
    },
    methods: {
        backToHome() {
            this.$router.push('/');
        },
    },
};
</script>
<style scoped lang="scss">
.not-find {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .notfind-img {
        width: 350px;
        height: 259px;
        margin-top: 100px;
    }
    .back-btn {
        margin-top: 20px;
    }
}
</style>
